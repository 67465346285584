import { toast } from 'react-toastify'

export const alertSucess = () => toast.success('Salvo com Sucesso')

export const alertSucessEditable = message => toast.success(message)

export const alertSucessDelete = () => toast.success('Excluído com Sucesso')

export const alertError = error => toast.error('Erro: ' + error)

export const alertLoading = () => toast.info('Carregando...')

export const alertInfo = info => toast.info(info)
