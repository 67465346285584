/* eslint-disable complexity */
import { useSubscription } from '@apollo/client'
import React, { createContext } from 'react'
import { getUserId, logout } from '../auth'
import { PageLoader } from '../Components/Loader'
import { GET_USER_WITH_PERMISSIONS } from '../queries/login'

export const UserContext = createContext()

export default function UserProvider({ children }) {
	const { loading, error, data } = useSubscription(GET_USER_WITH_PERMISSIONS, {
		variables: {
			userId: getUserId(),
		},
	})

	if (loading) return <PageLoader />
	if (error) {
		console.error(error)
		logout()
	}

	let user

	if (data) {
		try {
			user = {
				id: data.auth_users[0].id,
				name: data.auth_users[0].name,
				email: data.auth_users[0].email,
				actions: [],
			}
		} catch (e) {
			console.error(e)
			logout()
		}

		try {
			const groupPermissions = data.auth_users[0].user_groups.map(userGroup => userGroup.group.group_permissions)
			let actions = []

			for (const groupPermission of groupPermissions) {
				const groupPermissionActions = groupPermission.map(action => action.action)

				actions = [...actions, ...groupPermissionActions]
			}

			user = {
				...user,
				actions,
			}
		} catch (e) {
			console.error(e)
		}
	}

	return <UserContext.Provider value={user}>{children}</UserContext.Provider>
}
