/* eslint-disable complexity */

import {
	RESET_STATE, SET_DELETE_MARKET_MODAL_DATA,
	SET_DELETE_MARKET_MODAL_VISIBLE,
	SET_EDIT_MARKET_MODAL_DATA,
	SET_EDIT_MARKET_MODAL_VISIBLE,
	SET_ITEMS_OPTIONALS,
	SET_ITEMS_SELECTED,
	SET_MARKET_SELECTED,
	SET_SEARCH_ITEM,
	SET_VIEW_ITEMS_MARKETS
} from '../actions/actionsMarkets'

const INITIAL_STATE = {
	marketSelected: { name: '', id: '' },
	visibleDeleteMarketModal: false,
	dataDeleteMarketModal: {},
	visibleEditMarketModal: false,
	dataEditMarketModal: {},
	viewItemsMarkets: 'empty',
	itemsOptionals: [],
	itemsSelecteds: [],
	searchItem: '',
}

function marketsReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case RESET_STATE: {
			return { ...INITIAL_STATE }
		}

		case SET_MARKET_SELECTED: {
			return { ...state, marketSelected: action.value }
		}

		case SET_DELETE_MARKET_MODAL_DATA: {
			return { ...state, dataDeleteMarketModal: action.value }
		}

		case SET_DELETE_MARKET_MODAL_VISIBLE: {
			return { ...state, visibleDeleteMarketModal: action.value }
		}

		case SET_EDIT_MARKET_MODAL_DATA: {
			return { ...state, dataEditMarketModal: action.value }
		}

		case SET_EDIT_MARKET_MODAL_VISIBLE: {
			return { ...state, visibleEditMarketModal: action.value }
		}

		case SET_VIEW_ITEMS_MARKETS: {
			return { ...state, viewItemsMarkets: action.value }
		}

		case SET_SEARCH_ITEM: {
			return { ...state, searchItem: action.value }
		}

		case SET_ITEMS_SELECTED: {
			return { ...state, itemsSelecteds: action.value }
		}

		case SET_ITEMS_OPTIONALS: {
			return { ...state, itemsOptionals: action.value }
		}

		default: {
			return state
		}
	}
}

export default marketsReducer
