import { SET_SEARCH, SET_TAGS } from '../actions/actionsSearch'

const INITIAL_STATE = {
    search: '',
    tags: [],
}

export default function optionReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_SEARCH: {
            return {
                ...state,
                search: action.value,
            }
        }
        case SET_TAGS: {
            return {
                ...state,
                tags: action.value,
            }
        }

        default: {
            return state
        }
    }
}
