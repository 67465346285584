import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface FilterValues {
	[key: string]: string[]
}

interface FilterProps {
	column: string
	values: string[]
}

interface initialStateProps {
	showFilterMenu: Boolean
	filterValues: FilterValues
	filter: FilterProps[]
}

const initialState: initialStateProps = {
	showFilterMenu: false,
	filterValues: {},
	filter: [],
}

const followUpSlice = createSlice({
	name: 'followUp',
	initialState,
	reducers: {
		setShowFilterMenu(state, action: PayloadAction<Boolean>) {
			state.showFilterMenu = action.payload
		},

		setFilterValues(state, action: PayloadAction<FilterValues>) {
			state.filterValues = action.payload
		},

		setFilter(state, action: PayloadAction<FilterProps[]>) {
			state.filter = action.payload
		},

		resetState(state) {
			state = initialState
		}
	},
})

export const { setShowFilterMenu, setFilterValues, setFilter, resetState } = followUpSlice.actions
export default followUpSlice.reducer
