import React, { Fragment, Suspense } from 'react'
import { Redirect, Route } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import ErrorBoundary from '../../Components/Error/ErrorBoundary'
import { PageLoader } from '../../Components/Loader'
import Login from '../../Pages/Login'

const General = React.lazy(() => import('../../Pages/General'))
const Administration = React.lazy(() => import('../../Pages/Administration'))
const BoatManager = React.lazy(() => import('../../Pages/BoatManager'))
const PosManagement = React.lazy(() => import('../../Pages/PosManagement'))
const Report = React.lazy(() => import('../../Pages/Report'))

const AppMain = () => {
	return (
		<Fragment>
			<ErrorBoundary>
				<Route path='/login'>
					<Login />
				</Route>
			</ErrorBoundary>

			<Suspense fallback={<PageLoader />}>
				<ErrorBoundary>
					<Route
						path='/geral'
						component={General}
					/>
				</ErrorBoundary>
			</Suspense>

			<Suspense fallback={<PageLoader />}>
				<ErrorBoundary>
					<Route
						path='/administracao'
						component={Administration}
					/>
				</ErrorBoundary>
			</Suspense>

			<Suspense fallback={<PageLoader />}>
				<ErrorBoundary>
					<Route
						path='/embarcacao'
						component={BoatManager}
					/>
				</ErrorBoundary>
			</Suspense>

			<Suspense fallback={<PageLoader />}>
				<ErrorBoundary>
					<Route
						path='/gestao-de-pos'
						component={PosManagement}
					/>
				</ErrorBoundary>
			</Suspense>

			<Suspense fallback={<PageLoader />}>
				<ErrorBoundary>
					<Route
						path='/relatorios'
						component={Report}
					/>
				</ErrorBoundary>
			</Suspense>

			<Route
				exact
				path='/'
				render={() => <Redirect to='/geral/dashboard' />}
			/>
		</Fragment>
	)
}

export default AppMain
