import { gql } from '@apollo/client';

export const GET_ALL_PARAMETERS = gql`
  subscription getAllParameters{
    parameters{
      parameter
      value
    }
  }
`

export const UPDATE_PARAMETERS = gql`
  mutation updateParameters($approvalRequired: jsonb = "", $qtd: jsonb= "") {
    updateApproval: update_parameters(where: {
      parameter: {_eq: "DIRECTOR_APPROVAL_REQUIRED"}
    },
      _set: {value: $approvalRequired}) {
      affected_rows
    }
    
    updateQtd: update_parameters(where: {
      parameter: {_eq: "DIRECTOR_APPROVAL_QTD"}
    },
      _set: {value: $qtd}) {
      affected_rows
    }
  }
`

export const UPDATE_ITEM_ALERT = gql`
  mutation updateParameters($itemsTypeAlert: jsonb!) {
    updateItemsAlert: update_parameters(where: {parameter: {_eq: "ITEM_TYPE_ALERT"}}, _set: {value: $itemsTypeAlert}) {
      affected_rows
    }
  }
`

export const UPDATE_DASHBOARD_APPROVAL_ORDER = gql`
  mutation updateDashboardApprovalOrder($order: jsonb = "") {
    updateOrder: update_parameters(
      where: { parameter: { _eq: "DASHBOARD_APPROVAL_ORDER" } },
      _set: { value: $order }
    ) {
      affected_rows
    }
  }
`;
