// Actions types

// RESET STATE
export const RESET_STATE = 'RESET_STATE'

export const setResetState = () => ({
	type: RESET_STATE,
})

// SYNC
export const SET_SYNC_ITEMS = 'SET_SYNC_ITEMS '
export const setSyncItems = value => ({
	type: SET_SYNC_ITEMS,
	value,
})

// Standard
export const SET_STANDARD_SELECTED = 'SET_STANDARD_SELECTED'
export const SET_STANDARD_SELECT = 'SET_STANDARD_SELECT'
export const SET_VISIBLE_REMOVE_STANDARD_MODAL = 'SET_VISIBLE_DELETE_STANDARD_MODAL'
export const SET_VISIBLE_EDIT_STANDARD_MODAL = 'SET_VISIBLE_EDIT_STANDARD_MODAL'
export const SET_VISIBLE_ADD_STANDARD_MODAL = 'SET_VISIBLE_ADD_STANDARD_MODAL'
export const SET_LAST_CHILD_NUM = 'SET_LAST_CHILD_NUM'

export const setStandardSelected = value => ({
	type: SET_STANDARD_SELECTED,
	value,
})

export const setStandardSelect = value => ({
	type: SET_STANDARD_SELECT,
	value,
})

export const setVisibleAddStandardModal = value => ({
	type: SET_VISIBLE_ADD_STANDARD_MODAL,
	value,
})

export const setVisibleRemoveStandardModal = value => ({
	type: SET_VISIBLE_REMOVE_STANDARD_MODAL,
	value,
})

export const setVisibleEditStandardModal = value => ({
	type: SET_VISIBLE_EDIT_STANDARD_MODAL,
	value,
})

export const setLastChildNum = value => ({
	type: SET_LAST_CHILD_NUM,
	value,
})

// Scopes
export const SET_SCOPE_SELECTED_OF_STANDARD = 'SET_SCOPE_SELECTED_OF_STANDARD'
export const SET_SCOPES_SELECTED_TO_ADD_IN_STANDARD = 'SET_SCOPES_SELECTED_TO_ADD_IN_STANDARD'
export const SET_SCOPE_SELECT = 'SET_SCOPE_SELECT'
export const SET_VISIBLE_REMOVE_SCOPE_MODAL = 'SET_VISIBLE_REMOVE_SCOPE_MODAL'
export const SET_VISIBLE_EDIT_SCOPE_MODAL = 'SET_VISIBLE_EDIT_SCOPE_MODAL'
export const SET_VISIBLE_ADD_SCOPE_MODAL = 'SET_VISIBLE_ADD_SCOPE_MODAL'
export const SET_SCOPES_SELECTED_TO_ADD = 'SET_SCOPES_SELECTED_TO_ADD'
export const SET_SEARCH_SCOPE_VALUE = 'SET_SEARCH_SCOPE_VALUE'

export const setScopeSelected = value => ({
	type: SET_SCOPE_SELECTED_OF_STANDARD,
	value,
})

export const setScopeSelect = value => ({
	type: SET_SCOPE_SELECT,
	value,
})

export const setVisibleAddScopeModal = value => ({
	type: SET_VISIBLE_ADD_SCOPE_MODAL,
	value,
})

export const setVisibleRemoveScopeModal = value => ({
	type: SET_VISIBLE_REMOVE_SCOPE_MODAL,
	value,
})

export const setVisibleEditScopeModal = value => ({
	type: SET_VISIBLE_EDIT_SCOPE_MODAL,
	value,
})

export const setScopesSelectedToAdd = value => ({
	type: SET_SCOPES_SELECTED_TO_ADD,
	value,
})

export const setScopesSelectedToAddInStandard = value => ({
	type: SET_SCOPES_SELECTED_TO_ADD_IN_STANDARD,
	value,
})

export const setSearchScopeValue = value => ({
	type: SET_SEARCH_SCOPE_VALUE,
	value,
})

// Items
export const SET_ITEM_SELECTED = 'SET_ITEM_SELECTED'
export const SET_VISIBLE_DELETE_ITEM_MODAL = 'SET_VISIBLE_DELETE_ITEM_MODAL'
export const SET_VISIBLE_EDIT_ITEM_MODAL = 'SET_VISIBLE_EDIT_ITEM_MODAL'
export const SET_VISIBLE_ADD_ITEM_MODAL = 'SET_VISIBLE_ADD_ITEM_MODAL'
export const SET_ITEMS_SELECTEDS = 'SET_ITEMS_SELECTEDS'
export const SET_SEARCH_ITEM_VALUE = 'SET_SEARCH_ITEM_VALUE'
export const SET_ITEMS_IN_SCOPE = 'SET_ITEMS_IN_SCOPE'

export const setItemSelected = value => ({
	type: SET_ITEM_SELECTED,
	value,
})

export const setVisibleAddItemModal = value => ({
	type: SET_VISIBLE_ADD_ITEM_MODAL,
	value,
})

export const setVisibleDeleteItemModal = value => ({
	type: SET_VISIBLE_DELETE_ITEM_MODAL,
	value,
})

export const setVisibleEditItemModal = value => ({
	type: SET_VISIBLE_EDIT_ITEM_MODAL,
	value,
})

export const setItemsSelecteds = value => ({
	type: SET_ITEMS_SELECTEDS,
	value,
})

export const setSearchItemValue = value => ({
	type: SET_SEARCH_ITEM_VALUE,
	value,
})

export const setItemsInScope = value => ({
	type: SET_ITEMS_IN_SCOPE,
	value,
})

// Base boat
export const SET_BASE_BOAT = 'SET_BASE_BOAT'
export const SET_OPEN_SELECT_BASE_BOAT = 'SET_OPEN_SELECT_BASE_BOAT'

export const setBaseBoat = value => ({
	type: SET_BASE_BOAT,
	value,
})

export const setOpenSelectBaseBoat = value => ({
	type: SET_OPEN_SELECT_BASE_BOAT,
	value
})


