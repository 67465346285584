import React, { Component } from 'react'

// Styles and components
import ErrorFallback from './ErrorFallback'

// Others imports
import PropTypes from 'prop-types'

class ErrorBoundary extends Component {
	constructor(props) {
		super(props)
		this.state = {
			hasError: false,
			errorMessage: '',
		}
	}

	static getDerivedStateFromError(error) {
		// Atualiza o state para que a próxima renderização mostre a UI alternativa.
		return {
			hasError: true,
			errorMessage: error.message,
		}
	}

	// componentDidCatch(error, errorInfo) {
	// 	// Você também pode registrar o erro em um serviço de relatórios de erro
	// 	console.log(error, errorInfo)
	// }

	render() {
		if (this.state.hasError) {
			// Você pode renderizar qualquer UI alternativa
			return <ErrorFallback error={this.state.errorMessage} />
		}

		return this.props.children
	}
}

ErrorBoundary.propTypes = {
	children: PropTypes.element.isRequired,
}

export default ErrorBoundary
