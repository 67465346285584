import { ApolloClient, InMemoryCache } from '@apollo/client'
import { split } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { HttpLink } from 'apollo-link-http'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'
import { logout } from '../auth'

const createApolloClient = token => {
	const httpLink = new HttpLink({
		uri: process.env.REACT_APP_HASURA_URL,
		headers: {
			// 'x-hasura-admin-secret': 'SDUdv9TcAxZtsQNd',
			Authorization: `Bearer ${token}`,
			'content-type': 'application/json',
		},

		// cache: new InMemoryCache(),
	})

	const wsLink = new WebSocketLink({
		uri: process.env.REACT_APP_HASURA_URL_WS,
		options: {
			reconnect: true,
			connectionParams: {
				headers: {
					Authorization: `Bearer ${token}`,
					// 'x-hasura-admin-secret': 'SDUdv9TcAxZtsQNd',
					// 'content-type': 'application/json',
				},
			},
		},
	})

	const link = split(
		({ query }) => {
			const { kind, operation } = getMainDefinition(query)

			return kind === 'OperationDefinition' && operation === 'subscription'
		},

		wsLink,
		httpLink
	)

	const errorLink = onError(
		// eslint-disable-next-line complexity
		({ graphQLErrors, networkError }) => {
			if (networkError) {
				if (
					networkError.extensions.code === 'validation-failed' ||
					networkError.extensions.code === 'access-denied'
				) {
					logout()
				}
			}

			if (graphQLErrors) {
				for (const err of graphQLErrors) {
					if (err.extensions) {
						// eslint-disable-next-line max-depth
						if (err.extensions.code === 'validation-failed' || err.extensions.code === 'access-denied') {
							logout()
						}
					}
				}
			}
		}
	)

	return new ApolloClient({
		connectToDevTools: true,
		link: errorLink.concat(link),
		cache: new InMemoryCache({
			typePolicies: {
				production_orders: {
					keyFields: ['absolute_entry'],
				},
				production_order_items: {
					keyFields: ['production_order_absolute_entry'],
				},
				items_trees: {
					keyFields: ['scope_code', 'child_num'],
				},
				purchase_orders: {
					keyFields: ['line_num', 'doc_num'],
				},
				items: {
					keyFields: ['code'],
				},
				projects: {
					keyFields: ['code'],
				},
				actionGetScopes: {
					keyFields: ['where']
				}
			},
		}),
	})
}

export default createApolloClient
