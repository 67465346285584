/* eslint-disable complexity */
import { 
	CHANGE_MODAL_BOM_APPROVAL_VISIBLE, 
	CHANGE_MODAL_BOM_DIRECTOR_APPROVAL_VISIBLE,
	CHANGE_MODAL_DISAPPROVEL_REASON, 
	RESET_STATE } from '../actions/actionsDashboard'

const INITIAL_STATE = {
	modalBomApprovalVisible: false,
	modalDisapproveReason: false,
	modalBomDirectorApprovalVisible: false
}

export default function itemsScopesReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case RESET_STATE: {
			return { ...INITIAL_STATE }
		}

		case CHANGE_MODAL_BOM_APPROVAL_VISIBLE: {
			return { ...state, modalBomApprovalVisible: action.visible }
		}

		case CHANGE_MODAL_BOM_DIRECTOR_APPROVAL_VISIBLE: {
			return { ...state, modalBomDirectorApprovalVisible: action.visible }
		}

		case CHANGE_MODAL_DISAPPROVEL_REASON: {
			return { ...state, modalDisapproveReason: action.value }
		}

		default: {
			return state
		}
	}
}
