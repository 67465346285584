import { ApolloProvider } from '@apollo/client'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { HashRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import './assets/base.scss'
import { auth, verifyLogin365 } from './auth'
import configureStore from './config/configureStore'
import UserProvider from './context/UserProvider'
import Main from './Pages/Main'
import './polyfills'
import createApolloClient from './services/apolloClient'
import * as serviceWorker from './serviceWorker'
import './style.css'
import { datadogLogs } from '@datadog/browser-logs';
import ParametersProvider from './context/ParametersProvider'

// datadogLogs.init({
// 	clientToken: 'pub65af6bded39093d2fad3c4193d5caa5f',
// 	site: 'datadoghq.com',
// 	forwardErrorsToLogs: true,
// 	sessionSampleRate: 100
// });

const rootElement = document.getElementById('root')

const renderApp = () => {
	if (process.env.NODE_ENV === 'production') {
		// console.log = () => {}
		// console.debug = () => {}
	}

	verifyLogin365()

	const store = configureStore()
	const client = createApolloClient(auth.currentTokenValue)

	ReactDOM.render(
		<Provider store={{ ...store }}>
			<ApolloProvider client={client}>
				<UserProvider>
					<ParametersProvider>
						<HashRouter>
							<Main />
							<ToastContainer bodyClassName={"toast-body"} />
						</HashRouter>
					</ParametersProvider>
				</UserProvider>
			</ApolloProvider>
		</Provider>,
		rootElement
	)
}

renderApp()

if (module.hot) {
	module.hot.accept('./Pages/Main', () => {
		// const NextApp = require('./Pages/Main').default

		renderApp()
		// NextApp
	})
}

serviceWorker.unregister()
