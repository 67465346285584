/* eslint-disable complexity */

import {
	RESET_STATE, SELECTED_ALL_SCOPES_IN_STANDARDS,
	SELECTED_TO_APPLY_WITH_REASONS,
	SET_SELECTED_APPLICATIONS,
	SET_SELECTED_APPLICATIONS_EMPTY,
	SET_SELECTED_ID_BOM_ID,
	SET_VIEW_MODE,
	SELECTED_TO_APPLY_FORCED,
	SELECTED_TO_APPLY_FORCED_EMPTY,
	RESET_SELECTEDS
} from '../actions/actionsBomApproval'

const INITIAL_STATE = {
	selectedBomBatchId: null,
	viewMode: null,
	selectedApplications: [],
	selectedAllScopesInStandards: false,
	selectedToApplyWithReasons: [],
	selectedToApplyForced: []
}

export default function itemsScopesReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case RESET_STATE: {
			return { ...INITIAL_STATE }
		}

		case SET_SELECTED_ID_BOM_ID: {
			return {
				...state,
				selectedBomBatchId: action.selectedBomBatchId,
			}
		}

		case SET_VIEW_MODE: {
			return {
				...state,
				viewMode: action.mode,
			}
		}

		case SET_SELECTED_APPLICATIONS: {
			let selectedTemp = [...state.selectedApplications]

			if (action.selectedApplication.code) {
				if (selectedTemp.some(item => item.code === action.selectedApplication.code)) {
					selectedTemp = selectedTemp.filter(item => item.code !== action.selectedApplication.code)
				} else {
					selectedTemp.push(action.selectedApplication)
				}
			} else {
				if (selectedTemp.some(item => item.id === action.selectedApplication.id)) {
					selectedTemp = selectedTemp.filter(item => item.id !== action.selectedApplication.id)
				} else {
					selectedTemp.push(action.selectedApplication)
				}
			}

			return {
				...state,
				selectedApplications: selectedTemp,
			}
		}

		case SET_SELECTED_APPLICATIONS_EMPTY: {
			return {
				...state,
				selectedApplications: INITIAL_STATE.selectedApplications,
			}
		}

		case SELECTED_ALL_SCOPES_IN_STANDARDS: {
			return { ...state, selectedAllScopesInStandards: action.value }
		}

		case SELECTED_TO_APPLY_WITH_REASONS: {
			return { ...state, selectedToApplyWithReasons: action.value }
		}

		case SELECTED_TO_APPLY_FORCED: {

			let selectedTemp = [...state.selectedToApplyForced]


			if (action.selectedApplication.code) {
				if (selectedTemp.some(item => item.code === action.selectedApplication.code)) {
					selectedTemp = selectedTemp.filter(item => item.code !== action.selectedApplication.code)
				} else {
					selectedTemp.push(action.selectedApplication)
				}
			} else {
				if (selectedTemp.some(item => item.id === action.selectedApplication.id)) {
					selectedTemp = selectedTemp.filter(item => item.id !== action.selectedApplication.id)
				} else {
					selectedTemp.push(action.selectedApplication)
				}
			}

			return {...state, selectedToApplyForced: selectedTemp}
		}

		case SELECTED_TO_APPLY_FORCED_EMPTY: {
			return {
				...state,
				selectedToApplyForced: INITIAL_STATE.selectedToApplyForced,
			}
		}

		case RESET_SELECTEDS: {
			return {
				...state,
				selectedApplications: [],
				selectedAllScopesInStandards: false,
				selectedToApplyWithReasons: [],
				selectedToApplyForced: []
			}
		}

		default: {
			return state
		}
	}
}
