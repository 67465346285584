export const SET_MODEL = 'SET_MODEL'
export const SET_OPTIONALS = 'SET_OPTIONALS'
export const SET_EXTRA = 'SET_EXTRA'
export const SET_REGULATION = 'SET_REGULATION'
export const SET_IMG = 'SET_IMG'
export const SET_STEP = 'SET_STEP'
export const SET_AMOUNT = 'SET_AMOUNT'
export const SET_ACTION_TYPE = 'SET_ACTION_TYPE'
export const RESET_STATE = 'RESET_STATE'
export const SET_VIEW_MODE = 'SET_VIEW_MODE'
export const SET_BOM_ID = 'SET_BOM_ID'
export const SET_CLIENT_CODE = 'SET_CLIENT_CODE'
export const SET_PROJECT_CODE = 'SET_PROJECT_CODE'
export const SET_START_PROJECT = 'SET_START_PROJECT'
export const SET_END_PROJECT = 'SET_END_PROJECT'

export const setClientCode = value => ({
	type: SET_CLIENT_CODE,
	value
})

export const setProjectCode = value => ({
	type: SET_PROJECT_CODE,
	value,
})

export const setStartProject = value => ({
	type: SET_START_PROJECT,
	value
})

export const setEndProject = value => ({
	type: SET_END_PROJECT,
	value
})

export const setBomId = value => ({
	type: SET_BOM_ID,
	value
})

export const setViewMode = value => ({
	type: SET_VIEW_MODE,
	value,
})

export const resetState = () => ({
	type: RESET_STATE
})

export const setActionType = value => ({
	type: SET_ACTION_TYPE,
	value,
})

export const setModel = value => ({
	type: SET_MODEL,
	value,
})

export const setOptionals = value => ({
	type: SET_OPTIONALS,
	value,
})

export const setExtra = value => ({
	type: SET_EXTRA,
	value,
})

export const setRegulation = value => ({
	type: SET_REGULATION,
	value,
})

export const setImg = value => ({
	type: SET_IMG,
	value,
})

export const setStep = value => ({
	type: SET_STEP,
	value,
})

export const setAmount = amount => ({
	type: SET_AMOUNT,
	amount,
})
