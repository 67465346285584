import Loader from 'react-loaders'
import { Button, Container, Label } from 'reactstrap'
import styled, { css } from 'styled-components'

//Icons
export const ActiveIcon = styled.div`
${({ enabled }) => css`
	background-color: ${enabled ? ' #3ac47d' : '#ec6565'};
`};
border-radius: 100px
width: 15px;
height: 15px;
`

export const CloseIcon = styled.img`
        margin-left: 5px
        height: 20px
        width: 20px;
        cursor: pointer;
        
`

export const EditIcon = styled.img`
        margin-right: 5px
        height: 20px
        width: 20px
        cursor: pointer;
`

export const EmailIcon = styled.img`
        margin-right: 5px
        height: 20px
        width: 20px
        color: #5e629f
`

export const Icon = styled.img`
        height: 20px
        width: 20px;
        cursor: pointer;
`

//Containers
export const BodyContainer = styled.div`

        background-size: cover
        background-position: center
        background-repeat: no-repeat
        width: 100vw
        height: 100vh
        display: flex
        align-items: center
        justify-content: center
`

export const SmallScrollbleContainer = styled.div`
    width: 100%
    overflow-y: auto
    overflow-x: hidden
    max-height: 35vh
`

export const MediumScrollbleContainer = styled.div`
    width: 100%
    overflow: auto
    white-space: nowrap
    max-height: 40vh
`

export const LargeScrollbleContainer = styled.div`
    width: 100%
    overflow-y: auto
    overflow-x: hidden
    max-height: 60vh
`

export const ContainerCoverWizard = styled.div`
	height: 55vh;
`

export const LabelFilialItem = styled.div`    
    display: flex
    flex-flow: row nowrap
    flex-direction: row
    height: 20px
    padding: 5px
    font-size: 0.7rem;
    align-items: center`

export const ImgContainer = styled.img`
    width: 200px
    height: 200px
    border-radius: 30px
`

export const PrimaryContainer = styled(Container)`
        justify-content: center
        margin-top: 0%
        margin-left: auto
        margin-right: auto
        text-align: center
`

export const ImageContainer = styled.div`
    height: 100px
    width: 100px
`

//RegulationOptions
export const SelectedRegulationOptions = styled.div`
    width: 170px
    padding-top: 20px
    padding-bottom: 20px
    border-style: solid
    border-width: 1px
    border-radius: 25px
    border-color: #5e639f
    color: white
    background-color: #5e639f
    text-align: center
    display: inline
    font-size: 20px
    font-weight: 600
    margin-top: 10px
    margin-left: 40px
    margin-bottom: 40px
    outline: none
    cursor: pointer
    box-shadow: 5px 5px 10px #babaca
`

export const RegulationOptions = styled.div`
    width: 170px
    padding-top: 20px
    padding-bottom: 20px
    border-style: solid
    border-width: 1px
    border-radius: 25px
    border-color: #5e639f
    color: #5e639f
    background-color: transparent
    text-align: center
    display: inline
    font-size: 20px
    font-weight: 600
    margin-top: 10px
    margin-left: 40px
    margin-bottom: 40px
    outline: none
    cursor: pointer
    box-shadow: 5px 5px 10px #babaca
    :hover {
        transition: 0.3s
        color: white
        background-color: #5e639f
    }
`

//Optionals
export const OptionalsButton = styled.button`
    width: 100%
    border: none
    background-color: transparent
    color: #5e639f
`

export const DragIndicator = styled.div`
    border-style: solid
    border-width: 1.3px
    border-radius: 30px
    border-color: ${props => (props.color ? props.color : '#5e639f')}
    background-color: ${props => (props.color ? props.color : '#5e639f')}
    margin-right: 10px
    width: 1px
    height: 30px
`

//Buttons
export const ArrowButton = styled.div`
    color: #5e639f
    border: 1px solid #5e639f
    font-weight: 200
    font-size: 30px
    border-radius: 100%
    height: 50px;
    width: 50px;
    background-color: transparent
    :hover {
        border-radius: 30px
        background-color: #5e639f
        border-color: #5e639f
        i{
            color: white
        }
    }
`

export const MicrosoftButton = styled(Button)`
    padding: 8px
    border: 0px
    border-radius: 20px
    background: #393D72 0% 0% no-repeat padding-box
    cursor: pointer

    :hover {
        background-color: #373c73
    }

`
export const NoneButton = styled.button`
    border: none
    background-color: transparent
    padding: 0
`
export const LinkButton = styled.button`
        color: white
        font-size: 10px
        background-color: transparent
        border-color: transparent
        margin: 0
        
        :hover {
            background-color: transparent
            border-color: transparent
            color: #393D72
        }

        :active {
            background-color: transparent
            border-color: transparent
            color: white
        }
`
export const NewGroupButton = styled.button`
        font-weight: 500
        font-size: 14px
        background-color: transparent
        cursor: pointer
        color: #5e629f
        display: flex
        align-items: center
        border: none
        :hover{
            background-color: transparent
        }

        :active {
            color: #545cd8
            background-color: transparent
        }
`
export const NewReasonButton = styled.button`
        font-weight: 500
        font-size: 14px
        background-color: transparent
        cursor: pointer
        color: #5e629f
        display: flex
        align-items: center
        border: none
        :hover{
            background-color: transparent
        }

        :active {
            color: #545cd8
            background-color: transparent
        }
`

export const RoundedButton = styled(Button)`
    ${({ size }) => css`
		${size ? 'width: ' + size + 'px' : ''};
	`};
    color: white
    border-radius: 35px
    ${({ color }) => css`
		background-color: ${color};
		border-color: ${color};
	`};
    &:hover {
        transition: 0.2s
    }
`
export const RoundedButtonOutline = styled(Button)`
    border-color: #5e639f
    color: #5e639f
    border-radius: 35px
    background-color: transparent
    &:hover {
        color: white;
        background-color: #5e639f;
        transition: 0.2s
    }
`

//Labels, texts, spans
export const PrimaryTitleLabel = styled(Label)`
    display: block
    color: #5e639f
    font-size: 14px
    font-weight: 650s
`

export const PrimaryBoldLabel = styled(Label)`
    display: block
    color: #5e639f
    font-size: 15px
    font-weight: 600
`

export const PrimaryLabel = styled(Label)`
    display: block
    color: #5e639f
    font-size: 15px
    line-height: 15px
    white-space: pre-wrap

`
export const ObservationLabel = styled(Label)`
    display: block
    color: #707070
    font-size: 15px
    line-height: 15px
`
export const TitleLabel = styled(Label)`
        margin: 0px
        padding-top: 16px
        color: #C3C4CF
        font-size: 34px
        font: Montserrat
        font-weight: 600

    `

export const SubTitleLabel = styled(Label)`
        letter-spacing: 0px
        display: block
        font-size: 14px
        font-style: italic
        color: #FFFFFF
    `

export const TextArea = styled.textarea`
    display: block
    resize: none
    margin-left: 15px
    margin-bottom: 10px
    width: 95%
    height: 200px
    border-radius: 30px
    border-weight: 1px
    padding: 10px
    :focus {
        outline: none
        border-color: #719ECE
        box-shadow: 0 0 10px #719ECE
    }
`
export const SmallText = styled.span`
	${({ color }) => css`
		color: ${color ? color : '#707070'};
	`};
	font: 14px/18px Montserrat;
	font-weight: 500;
`

export const EllipsisLabel = styled.span`
    margin: 0
    overflow: hidden
    text-overflow: ellipsis
    font: 14px/18px Montserrat
    font-weight: 500;
    color: #707070
    width: 80%;
    white-space nowrap
`

export const SmallEllipsisLabel = styled.label`
    margin: 0
    overflow: hidden
    text-overflow: ellipsis
    font: 9px/7px Montserrat
    color: #707070
    width: 80%;
    white-space nowrap
`

export const SmallTextOverflow = styled.span`
	${({ color }) => css`
		color: ${color ? color : '#707070'};
	`};
	font: 12px Montserrat;
	font-weight: 500;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
`

export const LabelSmallTextOverflow = styled.label`
	${({ color }) => css`
		color: ${color ? color : '#707070'};
	`};
	margin: 0px;
	font: 12px Montserrat;
	font-weight: 500;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
`

export const TextBoldHighlight = styled.span`
	${({ color }) => css`
		color: ${color ? color : '#707070'};
	`};
	font: 10px/11px Montserrat;
	font-weight: 500;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	font-weight: 800;
	background-color: #efff64;
`

export const MediumTextOverflow = styled.span`
    margin: 0
    overflow: hidden
    text-overflow: ellipsis;
    font: 12px/14px Montserrat;
    color: #707070
    width: 80%;
    white-space nowrap;
    max-width: 20ch;
`

export const SmallSpan = styled.span`
	${({ color }) => css`
		color: ${color ? color : '#707070'};
	`};
	font: 10px/11px Montserrat;
	font-weight: 500;
`

export const SmallSpanBold = styled.span`
	${({ color }) => css`
		color: ${color ? color : '#707070'};
	`};
	font: 10px/11px Montserrat;
	font-weight: 800;
`

//Inputs
export const RoundedInput = styled.input`
        border-style: solid
        border-width: 1px
        border-color: #cccccc
        border-radius: 25px
        font-size: 15px
        width: 100%
        padding: 2px 8px
        padding-left: 15px
        display: block
        :focus {
            outline: none
            border-color: #719ECE
            box-shadow: 0 0 10px #719ECE
        }
`

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
        diplay: inline
        margin-right: 3px
`
export const TextUserInfo = styled.p`
        font-weight: 600
        color: #5e639f
        text-align:right
        font-size:16px/19px
        display:block
`
export const TextUserInfoEnd = styled.p`
        color: #babaca
        text-align:right
        font-size:14px/15px
        font-weight:500
`

//Tooltip

export const TooltipBox = styled.div`
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid #c9c9c9;
	border-radius: 6px;
	opacity: 1;
	visibility: hidden;
	position: absolute;
	padding: 4px;
	width: 150px;
`

export const TooltipText = styled.span`
	font: normal normal normal 10px/15px;
	letter-spacing: 0px;
	color: #707070;
	opacity: 1;
`

export const InfoTooltipIcon = styled.img`
	height: 16px;
	width: 16px;
	${({ margin }) => css`
		margin-left: ${margin}px;
	`}
`

export const TooltipCard = styled.div`
    & ${InfoTooltipIcon}:hover + ${TooltipBox} {
    visibility: visible;
    position: absolute;
    bottom: 50%;
    left: 72%;
    z-index: 9999
`

//Loaders

export const LoaderPulse = styled(Loader)`
	${({ show }) => css`
		display: ${show ? 'block' : 'hidden'};
	`}
	position: absolute;
	top: 50%;
	left: 50%;
	margin-right: -50%;
	transform: translate(-50%, -50%);
`
