export const SET_ITEMS_SCOPES = 'SET_ITEMS_SCOPES'
export const SET_ITEMS_CHANGED = 'SET_ITEMS_CHANGED'
export const SET_SELECTED_PRODUCTION_ORDERS = 'SET_SELECTED_PRODUCTION_ORDERS'
export const SET_QUEUE_ALTERATION = 'SET_QUEUE_ALTERATION'
export const SET_EDIT_STAGE_SCOPE_SELECTED = 'SET_EDIT_STAGE_SCOPE_SELECTED'
export const SET_OPEN_STAGE_ITEM_MODAL = 'SET_OPEN_STAGE_ITEM_MODAL'
export const SET_ITEM_SELECTED = 'SET_ITEM_SELECTED'
export const SET_SCOPE_SELECTED = 'SET_SCOPE_SELECTED'
export const SET_ITEM_SELECTED_TO_EDIT_STAGE = 'SET_ITEM_SELECTED_TO_EDIT_STAGE'
export const SET_SCOPES_ITEMS_CHANGED = 'SET_SCOPES_ITEMS_CHANGED'
export const SET_ITEMS_SELECTED_TO_ADD_IN_SCOPE = 'SET_ITEMS_SELECTED_TO_ADD_IN_SCOPE'
export const SET_DATA_TABLE = 'SET_DATA_TABLE'
export const SET_EDIT_STAGE_OPEN = 'SET_EDIT_STAGE_OPEN'
export const SET_ADD_ITEMS_TO_SCOPE_MODAL = 'SET_ADD_ITEMS_TO_SCOPE_MODAL'
export const SET_PREVIEW_CHANGES_OF_SCOPE = 'SET_PREVIEW_CHANGES_OF_SCOPE'
export const SET_SCOPE_CHANGE = 'SET_SCOPE_CHANGE'
export const SET_ITEM_SELECTED_TO_REMOVE = 'SET_ITEM_SELECTED_TO_REMOVE'
export const SET_REMOVE_ITEM_OPEN = 'SET_REMOVE_ITEM_OPEN'
export const RESET_STATE = 'RESET_STATE'
export const SET_SELECT_ALL = 'SET_SELECT_ALL'
export const SET_SEARCH_SCOPES = 'SET_SEARCH_SCOPES'

export const setSearchScopes = value => ({
	type: SET_SEARCH_SCOPES,
	value
})

export const setSelectAll = value => ({
	type: SET_SELECT_ALL,
	value
})

export const resetState = () => ({
	type: RESET_STATE
})

export const setItemsScope = value => ({
	type: SET_ITEMS_SCOPES,
	value,
})

export const setItemsChanged = value => ({
	type: SET_ITEMS_CHANGED,
	value,
})

export const setSelectedProductionOrder = value => ({
	type: SET_SELECTED_PRODUCTION_ORDERS,
	value,
})

export const setQueueAlteration = value => ({
	type: SET_QUEUE_ALTERATION,
	value,
})

export const setEditStageScopeSelected = value => ({
	type: SET_EDIT_STAGE_SCOPE_SELECTED,
	value,
})

export const setOpenStageItemModal = value => ({
	type: SET_OPEN_STAGE_ITEM_MODAL,
	value,
})

export const setItemSelected = value => ({
	type: SET_ITEM_SELECTED,
	value,
})

export const setScopeSelected = value => ({
	type: SET_SCOPE_SELECTED,
	value,
})

export const setItemToEditStage = value => ({
	type: SET_ITEM_SELECTED_TO_EDIT_STAGE,
	value,
})

export const setItemToRemove = value => ({
	type: SET_ITEM_SELECTED_TO_REMOVE,
	value,
})

export const setRemoveItemOpen = value => ({
	type: SET_REMOVE_ITEM_OPEN,
	value,
})

export const setScopeItemsChanged = value => ({
	type: SET_SCOPES_ITEMS_CHANGED,
	value,
})

export const setItemsSelectedToAddInScope = value => ({
	type: SET_ITEMS_SELECTED_TO_ADD_IN_SCOPE,
	value,
})

export const setDataTable = value => ({
	type: SET_DATA_TABLE,
	value,
})

export const setEditStageOpen = value => ({
	type: SET_EDIT_STAGE_OPEN,
	value,
})

export const setAddItemsToScopeModal = value => ({
	type: SET_ADD_ITEMS_TO_SCOPE_MODAL,
	value,
})

export const setPreviewChangesOfScope = value => ({
	type: SET_PREVIEW_CHANGES_OF_SCOPE,
	value,
})

export const setChangeScope = value => ({
	type: SET_SCOPE_CHANGE,
	value,
})
