export const SET_MARKET_SELECTED = 'SET_MARKET_SELECTED'
export const SET_EDIT_MARKET_MODAL_VISIBLE = 'SET_EDIT_MARKET_VISIBLE'
export const SET_EDIT_MARKET_MODAL_DATA = 'SET_EDIT_MARKET_DATA'
export const SET_DELETE_MARKET_MODAL_VISIBLE = 'SET_DELETE_MARKET_VISIBLE'
export const SET_DELETE_MARKET_MODAL_DATA = 'SET_DELETE_MARKET_MODAL_DATA'
export const SET_VIEW_ITEMS_MARKETS = 'SET_VIEW_ITEMS_MARKETS'
export const SET_SEARCH_ITEM = 'SET_SEARCH_ITEM'
export const SET_ITEMS_SELECTED = 'SET_ITEMS_SELECTED'
export const SET_ITEMS_OPTIONALS = 'SET_ITEMS_OPTIONALS'
export const RESET_STATE = 'RESET_STATE'

export const resetState = () => ({
	type: RESET_STATE
})

export const setMarketSelected = value => ({
	type: SET_MARKET_SELECTED,
	value,
})

export const setEditMarketModalVisible = value => ({
	type: SET_EDIT_MARKET_MODAL_VISIBLE,
	value,
})

export const setEditMarketModalData = value => ({
	type: SET_EDIT_MARKET_MODAL_DATA,
	value,
})

export const setDeleteMarketModalVisible = value => ({
	type: SET_DELETE_MARKET_MODAL_VISIBLE,
	value,
})

export const setDeleteMarketModalData = value => ({
	type: SET_DELETE_MARKET_MODAL_DATA,
	value,
})

export const setViewItemsMarkets = value => ({
	type: SET_VIEW_ITEMS_MARKETS,
	value,
})

export const setSearchItem = value => ({
	type: SET_SEARCH_ITEM,
	value,
})

export const setItemsSelecteds = value => ({
	type: SET_ITEMS_SELECTED,
	value,
})

export const setItemsOptionals = value => ({
	type: SET_ITEMS_OPTIONALS,
	value,
})
