/* eslint-disable complexity */
import {
	RESET_STATE, SET_ADD_ITEMS_TO_SCOPE_MODAL,
	SET_DATA_TABLE,
	SET_EDIT_STAGE_OPEN,
	SET_EDIT_STAGE_SCOPE_SELECTED,
	SET_ITEMS_CHANGED,
	SET_ITEMS_SCOPES,
	SET_ITEMS_SELECTED_TO_ADD_IN_SCOPE,
	SET_ITEM_SELECTED,
	SET_ITEM_SELECTED_TO_EDIT_STAGE,
	SET_ITEM_SELECTED_TO_REMOVE,
	SET_OPEN_STAGE_ITEM_MODAL,
	SET_PREVIEW_CHANGES_OF_SCOPE,
	SET_QUEUE_ALTERATION,
	SET_REMOVE_ITEM_OPEN,
	SET_SCOPES_ITEMS_CHANGED,
	SET_SCOPE_CHANGE,
	SET_SCOPE_SELECTED, SET_SEARCH_SCOPES, SET_SELECTED_PRODUCTION_ORDERS,
	SET_SELECT_ALL
} from '../actions/actionsItemsScopes'

const INITIAL_STATE = {
	itemsScopes: [],
	selectedProductionOrders: [],
	queueAlteration: [],
	editStageScopeSelected: { id: null },
	openStageItemModal: false,
	itemSelected: {},
	scopeSelected: { code: '' },
	itemSelectedToEditStage: {},
	itemsChanged: [],
	itemsSelectedToAddInScope: [],
	dataTable: [],
	editStageOpen: false,
	addItemsToScopeModal: false,
	previewChangesOfScope: [],
	changeScope: false,
	itemSelectedToRemove: {},
	removeItemOpen: false,
	selectAllOps: false,
	searchScopes: '',
}

function itemsScopesReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case SET_SEARCH_SCOPES: {
			return { ...state, searchScopes: action.value }
		}

		case SET_SELECT_ALL: {
			return { ...state, selectAllOps: action.value }
		}

		case RESET_STATE: {
			return { ...INITIAL_STATE }
		}

		case SET_ITEMS_SCOPES: {
			return { ...state, itemsScopes: action.value }
		}

		case SET_ITEMS_CHANGED: {
			return { ...state, itemsChanged: action.value }
		}

		case SET_SELECTED_PRODUCTION_ORDERS: {
			return { ...state, selectedProductionOrders: action.value }
		}

		case SET_QUEUE_ALTERATION: {
			return { ...state, queueAlteration: action.value }
		}

		case SET_EDIT_STAGE_SCOPE_SELECTED: {
			return { ...state, editStageScopeSelected: action.value }
		}

		case SET_OPEN_STAGE_ITEM_MODAL: {
			return { ...state, openStageItemModal: action.value }
		}

		case SET_ITEM_SELECTED: {
			return { ...state, itemSelected: action.value }
		}

		case SET_SCOPE_SELECTED: {
			return { ...state, scopeSelected: action.value }
		}

		case SET_ITEM_SELECTED_TO_EDIT_STAGE: {
			return { ...state, itemSelectedToEditStage: action.value }
		}

		case SET_SCOPES_ITEMS_CHANGED: {
			return { ...state, itemsChanged: action.value }
		}

		case SET_ITEMS_SELECTED_TO_ADD_IN_SCOPE: {
			return { ...state, itemsSelectedToAddInScope: action.value }
		}

		case SET_DATA_TABLE: {
			return { ...state, dataTable: action.value }
		}

		case SET_EDIT_STAGE_OPEN: {
			return { ...state, editStageOpen: action.value }
		}

		case SET_ADD_ITEMS_TO_SCOPE_MODAL: {
			return { ...state, addItemsToScopeModal: action.value }
		}

		case SET_PREVIEW_CHANGES_OF_SCOPE: {
			return { ...state, previewChangesOfScope: action.value }
		}

		case SET_SCOPE_CHANGE: {
			return { ...state, changeScope: action.value }
		}

		case SET_ITEM_SELECTED_TO_REMOVE: {
			return { ...state, itemSelectedToRemove: action.value }
		}

		case SET_REMOVE_ITEM_OPEN: {
			return { ...state, removeItemOpen: action.value }
		}

		default: {
			return state
		}
	}
}

export default itemsScopesReducer
