export const SET_OPTION = 'SET_OPTION'
export const SET_OPTIONAL = 'SET_OPTIONAL'
export const SET_OPEN = 'SET_OPEN'
export const SET_VIEW_ITEMS_OPTIONS = 'SET_VIEW_ITEMS_OPTIONS'
export const SET_SEARCH_ITEM = 'SET_SEARCH_ITEM'
export const SET_ITEMS_SELECTED = 'SET_ITEMS_SELECTED'
export const SET_ITEMS_OPTIONALS = 'SET_ITEMS_OPTIONALS'
export const SET_SELECTED_MARKETS = 'SET_SELECTED_MARKETS'
export const RESET_STATE = 'RESET_STATE'
export const SET_ITEMS = 'SET_ITEMS'

export const setItems = value => ({
	type: SET_ITEMS,
	value,
})

export const resetState = () => ({
	type: RESET_STATE,
})

export const setOptional = value => ({
	type: SET_OPTIONAL,
	value,
})

export const setSelectedMarkets = value => ({
	type: SET_SELECTED_MARKETS,
	value,
})

export const setOption = value => ({
	type: SET_OPTION,
	value,
})

export const setOpen = value => ({
	type: SET_OPEN,
	value,
})

export const setViewItemsOptions = value => ({
	type: SET_VIEW_ITEMS_OPTIONS,
	value,
})

export const setSearchItem = value => ({
	type: SET_SEARCH_ITEM,
	value,
})

export const setItemsSelecteds = value => ({
	type: SET_ITEMS_SELECTED,
	value,
})

export const setItemsOptionals = value => ({
	type: SET_ITEMS_OPTIONALS,
	value,
})
