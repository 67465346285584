export const RESET_STATE = 'RESET_STATE'
export const SET_CUSTOMIZED_BOM = 'SET_CUSTOMIZED_BOM'
export const SET_OPEN_DELETE_MODAL = 'SET_OPEN_DELETE_MODAL'
export const SET_OPEN_EDIT_MODAL = 'SET_OPEN_EDIT_MODAL'

export const setOpenEditModal = value => ({
	type: SET_OPEN_EDIT_MODAL,
	value
})

export const setCustomizedBom = value => ({
	type: SET_CUSTOMIZED_BOM,
	value
})


export const setOpenDeleteModal = value => ({
	type: SET_OPEN_DELETE_MODAL,
	value
})

export const resetState = () => ({ type: RESET_STATE })
