/* eslint-disable complexity */
import { RESET_STATE, SET_CUSTOMIZED_BOM, SET_OPEN_DELETE_MODAL, SET_OPEN_EDIT_MODAL } from '../actions/actionsSaveBoms'

const INITIAL_STATE = {
	openDeleteModal: false,
	openEditModal: false,
	customizedBom: null,
}

export default function saveBomsReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case SET_OPEN_EDIT_MODAL: {
			return { ...state, openEditModal: action.value }
		}

		case SET_OPEN_DELETE_MODAL: {
			return { ...state, openDeleteModal: action.value }
		}

		case SET_CUSTOMIZED_BOM: {
			return {
				...state,
				customizedBom: action.value,
			}
		}

		case RESET_STATE: {
			return { ...INITIAL_STATE }
		}

		default: {
			return state
		}
	}
}
