/* eslint-disable complexity */
import { BehaviorSubject } from 'rxjs'
import { alertError } from '../Pages/Alerts'
import { deleteAllCookies, getCookieInArray, getCookieString, updateCookiesArray } from '../utils/cookies'

const currentTokenSubject = new BehaviorSubject(getCookieString('base64AuthAccess'))

export const auth = {
	currentToken: currentTokenSubject.asObservable(),
	get currentTokenValue() {
		return currentTokenSubject.value
	},
}

export const verifyLogin365 = () => {
	if (document.cookie) {
		updateCookiesArray()
		const error = getCookieInArray('error')

		if (error) {
			alertError(error.value)
		} else {
			const token = getCookieInArray('base64AuthAccess')

			if (token) {
				currentTokenSubject.next(token.value)
			}
		}
	} else {
		logout()
	}
}

export const isAuthenticated = () => {
	return auth.currentTokenValue
}

export const getUserId = () => {
	return getCookieString('base64Id')
}

export const logout = () => {
	deleteAllCookies()
	currentTokenSubject.next(null)
	window.location.replace('#/login')
}
