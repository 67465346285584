export const CHANGE_MODAL_BOM_APPROVAL_VISIBLE = 'CHANGE_MODAL_BOM_APPROVAL_VISIBLE'
export const CHANGE_MODAL_BOM_DIRECTOR_APPROVAL_VISIBLE = 'CHANGE_MODAL_BOM_DIRECTOR_APPROVAL_VISIBLE'
export const CHANGE_MODAL_DISAPPROVEL_REASON = 'CHANGE_MODAL_DISAPPROVEL_REASON'
export const RESET_STATE = 'RESET_STATE'

export const resetState = () => ({
	type: RESET_STATE
})

export const changeModalBOMApprovalVisible = visible => ({
	type: CHANGE_MODAL_BOM_APPROVAL_VISIBLE,
	visible,
})

export const changeModalBOMDirectorApprovalVisible = visible => ({
	type: CHANGE_MODAL_BOM_DIRECTOR_APPROVAL_VISIBLE,
	visible,
})

export const changeModalDisapprovelReason = value => ({
	type: CHANGE_MODAL_DISAPPROVEL_REASON,
	value,
})
