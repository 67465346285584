import followUpReducer from '../Slices/followUpReducer'
import poManagementReducer from '../Slices/poManagementReducer'
import baseBoatReducer from './baseBoatReducer'
import bomApprovalReducer from './bomApprovalReducer'
import customizationReducer from './customizationReducer.js'
import dashboardReducer from './dashboardReducer'
import itemsScopesReducer from './itemsScopesReducer'
import marketsReducer from './marketsReducer'
import optionReducer from './optionReducer.js'
import searchReducer from './searchReducer.js'
import ThemeOptions from './ThemeOptions'
import saveBomsReducer from './saveBomsReducer'

export default {
	saveBomsReducer,
	ThemeOptions,
	customizationReducer,
	optionReducer,
	searchReducer,
	itemsScopesReducer,
	baseBoatReducer,
	dashboardReducer,
	bomApprovalReducer,
	marketsReducer,
	followUpReducer,
	poManagementReducer,
}
