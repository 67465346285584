import { Form } from "reactstrap"
import styled from "styled-components"

export const LoginButton = styled.button`
        font-size: 10px
        color: white
        margin-top: 25px
        border-radius: 25px
        border-color: #393D72
        border: none
        padding-top: 3px
        padding-bottom: 3px
        padding-left: 20px
        padding-right: 20px
        background-color: #393D72
        display: block
        margin-left: auto
        margin-right: auto

        :hover {
            background-color: #393D5F
        }

        :active {
            background-color: #393D72
        }
    `

export const FormLogin = styled(Form)`
    width: 300px
    heigh: 200px
    margin-left: auto
    margin-right: auto
`
export const ImgLogoLogin = styled.img`
        width: 340px
        height: 122px
        padding-bottom: 16px
        background: 0% 0% no-repeat padding-box
        margin-bottom: 3%
`

export const RoundedInputLogin = styled.input`
        border-style: solid
        border-color: white
        border-width: 1px
        border-radius: 25px
        color: white
        font-size: 11px
        background: transparent
        margin-bottom: 3%
        width: 100%
        padding: 6px
        padding-left: 15px
        display: block
        ::placeholder{
            color: white
        }
        :focus {
            outline: none
            transition: 0.2s
            border-color: #393D72
            background: white
            color: black
        }
     `