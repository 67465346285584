import PropTypes from 'prop-types'
import React from 'react'
import LoadingOverlay from 'react-loading-overlay'
import styled from 'styled-components'
import { LoaderPulse } from './Styles'

const StyledLoader = styled(LoadingOverlay)`
		.MyLoader_overlay {
			${props => props.customStyle}
		}
	`

export default function Loader({ active, children, customStyle }) {
	return (
		<StyledLoader
			customStyle={customStyle}
			active={active}
			spinner={<LoaderPulse type='ball-pulse' />}
			classNamePrefix='MyLoader_'
		>
			{children}
		</StyledLoader>
	)
}

export function PageLoader() {
	return (
		<div className='loader-container'>
			<div className='loader-container-inner'>
				<div className='text-center'>
					<LoaderPulse
						type='ball-pulse'
						active={true}
					/>
				</div>
			</div>
		</div>
	)
}

Loader.propTypes = {
	active: PropTypes.bool,
	children: PropTypes.any,
	customStyle: PropTypes.string,
}
