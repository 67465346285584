/* eslint-disable complexity */
import {
	RESET_STATE,
	SET_ITEMS,
	SET_ITEMS_OPTIONALS,
	SET_ITEMS_SELECTED,
	SET_OPEN,
	SET_OPTION,
	SET_OPTIONAL,
	SET_SEARCH_ITEM,
	SET_SELECTED_MARKETS,
	SET_VIEW_ITEMS_OPTIONS
} from '../actions/actionsOptions'

const INITIAL_STATE = {
	optionalSelected: { id: '', name: '' },
	option: {},
	open: false,
	selectedMarkets: [],
	viewItemsOptions: 'empty',
	itemsOptionals: [],
	itemsSelecteds: [],
	searchItem: '',
	items: []
}

export default function optionReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case SET_ITEMS: {
			return { ...state, items: action.value }
		}
		
		case RESET_STATE: {
			return { ...INITIAL_STATE }
		}

		case SET_OPTIONAL: {
			return {
				...state,
				optionalSelected: action.value,
			}
		}

		case SET_SELECTED_MARKETS: {
			return {
				...state,
				selectedMarkets: action.value,
			}
		}
		case SET_OPTION: {
			return {
				...state,
				option: action.value,
			}
		}

		case SET_OPEN: {
			return {
				...state,
				open: action.value,
			}
		}

		case SET_VIEW_ITEMS_OPTIONS: {
			return { ...state, viewItemsOptions: action.value }
		}

		case SET_SEARCH_ITEM: {
			return { ...state, searchItem: action.value }
		}

		case SET_ITEMS_SELECTED: {
			return { ...state, itemsSelecteds: action.value }
		}

		case SET_ITEMS_OPTIONALS: {
			return { ...state, itemsOptionals: action.value }
		}

		default: {
			return state
		}
	}
}
