/* eslint-disable complexity */

// Actions
import { CardOption } from '../../Pages/BoatManager/Pages/Customization/Pages/Components/CardOptions'
import {
	RESET_STATE,
	SET_ITEMS_IN_SCOPE,
	SET_ITEMS_SELECTEDS,
	SET_ITEM_SELECTED,
	SET_LAST_CHILD_NUM,
	SET_SCOPES_SELECTED_TO_ADD,
	SET_SCOPES_SELECTED_TO_ADD_IN_STANDARD,
	SET_SCOPE_SELECT,
	SET_SCOPE_SELECTED_OF_STANDARD,
	SET_SEARCH_ITEM_VALUE,
	SET_SEARCH_SCOPE_VALUE,
	SET_STANDARD_SELECT,
	SET_STANDARD_SELECTED,
	SET_SYNC_ITEMS,
	SET_VISIBLE_ADD_ITEM_MODAL,
	SET_VISIBLE_ADD_SCOPE_MODAL,
	SET_VISIBLE_ADD_STANDARD_MODAL,
	SET_VISIBLE_DELETE_ITEM_MODAL,
	SET_VISIBLE_EDIT_ITEM_MODAL,
	SET_VISIBLE_EDIT_SCOPE_MODAL,
	SET_VISIBLE_EDIT_STANDARD_MODAL,
	SET_VISIBLE_REMOVE_SCOPE_MODAL,
	SET_VISIBLE_REMOVE_STANDARD_MODAL,
	SET_BASE_BOAT,
	SET_OPEN_BASE_BOAT,
	SET_OPEN_SELECT_BASE_BOAT
} from '../actions/actionsBaseBoat'

const INITIAL_STATE = {
	// Sync data
	syncItems: false,

	// Standard card
	visibleAddStandardModal: false,
	visibleEditStandardModal: false,
	visibleDeleteStandardModal: false,
	standardSelected: { name: null, id: null },
	standardSelect: false,
	lastChildNum: 0,

	// Scopes card
	visibleAddScopeModal: false,
	visibleEditScopeModal: false,
	visibleRemoveScopeModal: false,
	scopeSelected: { name: null, id: null },
	scopeSelect: false,
	scopesSelectedToAddInStandard: [],
	searchScopeValue: 'empty',

	// Item card
	visibleRemoveItemModal: false,
	visibleEditItemModal: false,
	visibleAddItemModal: false,
	itemSelected: { name: null, code: null },
	itemsSelecteds: [],
	searchItemValue: '',
	itemsInScope: [],
	baseBoat: null,
	openSelectBaseBoat: { visible: false, type: null }
}

export default function baseBoatReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		// Reset state
		case RESET_STATE: {
			return { ...INITIAL_STATE }
		}

		// Sync data
		case SET_SYNC_ITEMS: {
			return { ...state, syncItems: action.value }
		}

		// Standard
		case SET_STANDARD_SELECTED: {
			return {
				...state,
				standardSelected: action.value,
			}
		}

		case SET_STANDARD_SELECT: {
			return { ...state, standardSelect: action.value }
		}

		case SET_VISIBLE_ADD_STANDARD_MODAL: {
			return { ...state, visibleAddStandardModal: action.value }
		}

		case SET_VISIBLE_EDIT_STANDARD_MODAL: {
			return { ...state, visibleEditStandardModal: action.value }
		}

		case SET_VISIBLE_REMOVE_STANDARD_MODAL: {
			return { ...state, visibleRemoveStandardModal: action.value }
		}

		case SET_LAST_CHILD_NUM: {
			return { ...state, lastChildNum: action.value }
		}

		// --------------------------------------------------------

		// Scopes
		case SET_SCOPE_SELECTED_OF_STANDARD: {
			return { ...state, scopeSelected: action.value }
		}

		case SET_SCOPE_SELECT: {
			return { ...state, scopeSelect: action.value }
		}

		case SET_VISIBLE_ADD_SCOPE_MODAL: {
			return { ...state, visibleAddScopeModal: action.value }
		}

		case SET_VISIBLE_EDIT_SCOPE_MODAL: {
			return { ...state, visibleEditScopeModal: action.value }
		}

		case SET_VISIBLE_REMOVE_SCOPE_MODAL: {
			return { ...state, visibleRemoveScopeModal: action.value }
		}

		case SET_SCOPES_SELECTED_TO_ADD: {
			return { ...state, scopesSelectedToAdd: action.value }
		}

		case SET_SCOPES_SELECTED_TO_ADD_IN_STANDARD: {
			return { ...state, scopesSelectedToAddInStandard: action.value }
		}

		case SET_SEARCH_SCOPE_VALUE: {
			return { ...state, searchScopeValue: action.value }
		}

		// -----------------------------------------------------------------

		// Items
		case SET_ITEM_SELECTED: {
			return { ...state, itemSelected: action.value }
		}

		case SET_VISIBLE_ADD_ITEM_MODAL: {
			return { ...state, visibleAddItemModal: action.value }
		}

		case SET_VISIBLE_DELETE_ITEM_MODAL: {
			return { ...state, visibleRemoveItemModal: action.value }
		}

		case SET_VISIBLE_EDIT_ITEM_MODAL: {
			return { ...state, visibleEditItemModal: action.value }
		}

		case SET_ITEMS_SELECTEDS: {
			return { ...state, itemsSelecteds: action.value }
		}

		case SET_SEARCH_ITEM_VALUE: {
			return { ...state, searchItemValue: action.value }
		}

		case SET_ITEMS_IN_SCOPE: {
			return { ...state, itemsInScope: action.value }
		}

		// ---------------------------------------------------------

		// Base boat

		case SET_BASE_BOAT: {
			return { ...state, baseBoat: action.value }
		}

		case SET_OPEN_SELECT_BASE_BOAT: {
			return { ...state, openSelectBaseBoat: action.value }
		}

		// ---------------------------------------------------------

		default: {
			return state
		}
	}
}
