import React, { Fragment } from 'react'
import Logo365 from '../../assets/images/365.svg'
import backgroundImage from '../../assets/images/fundo_login_1920x1085.png'
import LogoOkean from '../../assets/images/logo.svg'
import { BodyContainer, MicrosoftButton, PrimaryContainer, SubTitleLabel, TitleLabel } from '../../Components/Styles'
import { FormLogin, ImgLogoLogin } from './Components/Styles'

export default function Login() {
	// Functions
	function onClickMicrosoftAuth() {
		window.location.replace(`${process.env.REACT_APP_REDIRECT_AZURE_PROVIDER}`)
	}

	return (
		<Fragment>
			<BodyContainer style={{ backgroundImage: `url(${backgroundImage}` }}>
				<PrimaryContainer>
					<ImgLogoLogin
						src={LogoOkean}
						alt='logo-okean.svg'
					/>
					<FormLogin>
						<div style={{ marginBottom: '16px' }}>
							<TitleLabel>Bem-vindo!</TitleLabel>
							<SubTitleLabel>open up your world</SubTitleLabel>
						</div>
						<MicrosoftButton onClick={onClickMicrosoftAuth}>
							<img
								style={{
									cursor: 'pointer',
									marginRight: '8px',
									marginLeft: '8px',
									padding: '2px',
									diplay: 'inline',
									width: '22px',
									height: '26px',
								}}
								src={Logo365}
								alt='logo-office-365'
							/>
							<label
								style={{
									fontSize: '16px',
									fontWeight: 'medium',
									cursor: 'pointer',
									margin: '0px',
									marginRight: '12px',
									textAlign: 'left',
									letterSpacing: '0px',
									color: '#fff',
								}}>
								Entrar com Office 365
							</label>
						</MicrosoftButton>
					</FormLogin>
				</PrimaryContainer>
			</BodyContainer>
		</Fragment>
	)
}
