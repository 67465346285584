/* eslint-disable complexity */
import { useSubscription } from '@apollo/client'
import { resultKeyNameFromField } from 'apollo-utilities'
import React, { createContext } from 'react'
import { logout } from '../auth'
import { PageLoader } from '../Components/Loader'
import { GET_ALL_PARAMETERS } from '../queries/parameter'

export const ParametersContext = createContext()

export default function ParametersProvider({ children }) {

	const { loading, error, data } = useSubscription(GET_ALL_PARAMETERS)


	if (loading) return <PageLoader />
	if (error) {
		console.error(error)
		logout()
	}

	let parameters = {}

	if (data) {

		try {

			//data.parameters.forEach(p => parameters[p.parameter] = p.value)

			for(let p of data.parameters){
				parameters[p.parameter] = p.value
			}


		} catch (e) {
			console.error(e)
		}

	}

	return <ParametersContext.Provider value={parameters}>{children}</ParametersContext.Provider>
}
