import React from 'react'

// Styles and components
// import './style.css'

// Others imports
import PropTypes from 'prop-types'

function ErrorFallback({ error }) {
	return (
		<div className='main-error-page'>
			<h1 className='error-title'>
				Woops! <br />
				Something went wrong :(
				{error}
			</h1>
		</div>
	)
}

ErrorFallback.propTypes = {
	error: PropTypes.string,
}

export default ErrorFallback
