export const SET_SEARCH = "SET_SEARCH"
export const SET_TAGS = "SET_TAGS"

export const setSearch = (value) => ({
    type: SET_SEARCH,
    value
})

export const setTags = (value) => ({
    type: SET_TAGS,
    value
})