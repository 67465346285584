/* eslint-disable complexity */
import {
	RESET_STATE, SET_ACTION_TYPE,
	SET_AMOUNT,
	SET_BOM_ID,
	SET_EXTRA,
	SET_IMG,
	SET_MODEL,
	SET_OPTIONALS,
	SET_REGULATION,
	SET_VIEW_MODE,
	SET_CLIENT_CODE,
	SET_END_PROJECT,
	SET_PROJECT_CODE,
	SET_START_PROJECT
} from '../actions/actionsCustomization'

const INITIAL_STATE = {
	model: {},
	optionals: [],
	extra: '',
	regulation: '',
	market_id: '',
	image_path: '',
	amount: 0,
	actionType: '',
	viewMode: '',
	bomId: '',
	clientCode: '',
	projectCode: '',
	startProject: '',
	endProject: ''
}

export default function customizationReducer(state = INITIAL_STATE, action) {
	switch (action.type) {

		case SET_CLIENT_CODE: {
			return { ...state, clientCode: action.value }
		}

		case SET_PROJECT_CODE: {
			return { ...state, projectCode: action.value }
		}

		case SET_START_PROJECT: {
			return { ...state, startProject: action.value }
		}

		case SET_END_PROJECT: {
			return { ...state, endProject: action.value }
		}

		case SET_BOM_ID: {
			return { ...state, bomId: action.value }
		}

		case SET_VIEW_MODE: {
			return { ...state, viewMode: action.value }
		}

		case RESET_STATE: {
			return { ...INITIAL_STATE }
		}

		case SET_ACTION_TYPE: {
			return { ...state, actionType: action.value }
		}

		case SET_MODEL: {
			return {
				...state,
				model: action.value,
			}
		}

		case SET_OPTIONALS: {
			return {
				...state,
				optionals: action.value,
			}
		}

		case SET_EXTRA: {
			return {
				...state,
				extra: action.value,
			}
		}

		case SET_REGULATION: {
			return {
				...state,
				regulation: action.value.name,
				market_id: action.value.id,
			}
		}

		case SET_IMG: {
			return {
				...state,
				image_path: action.value,
			}
		}

		case SET_AMOUNT: {
			return { ...state, amount: action.amount }
		}

		default: {
			return state
		}
	}
}
