import { CardBody, Label } from 'reactstrap'
import styled, { css } from 'styled-components'

//General Card
export const CardImage = styled.div`
    height: 100%
    width: 100%
    background-color: transparent
    border-radius: 25px
    border-color: #959595
    border-style: solid
    border-width: 2px
    text-align: center
    box-shadow: 1px 1px 10px #babaca
`

export const CardDescriptionOptions = styled(Label)`
    font-size: 10px
    display: block
    color: #111111
`

export const CardTitleOptions = styled.span`
    font-size: 10px
    display: block
    color: #5e639f
    max-height: 45px
    overflow-y: hidden
    font-weight: 650
    max-width: 95%
    text-overflow: ellipsis
    white-space: nowrap
    overflow: hidden
`

export const CardItemComponent = styled(CardBody)`
    border-radius: 25px
    box-shadow: 2px 2px 10px #babaca    
    height: 120px;
    ${({ related }) => css`
	background-color: ${related === 'true' ? 'white' : 'rgba(128, 128, 128, 0.13)'};
`};
`

export const TitleCardComponents = styled(Label)`
    display: block
    color: #5e639f
    max-height: 45px
    overflow-y: hidden
    max-width: 100%
    text-overflow: ellipsis
    white-space: nowrap
    overflow: hidden
`

//Card containers
export const CardToggleContainer = styled.div`
	width: 60px;
`

export const CardDescriptionContainer = styled.div`
    height: 55px
    overflow: hidden
`

export const CardTitleContainer = styled.div`
	width: calc(100% - 60px);
    position:relative
`

export const BodyCardContainer = styled.div`
    height: 150px
    margin-left: 5% 
    width: calc(95% - 150px)
`

export const RegulationCard = styled.div`
    width: 100px
    height: 20px
    border-style: solid
    border-width: 1px
    border-radius: 35px
    border-color: #5e639f
    color: #5e639f
    background-color: transparent
    text-align: center
    display: flex
    justify-content: center
    align-items: center
`
//Market
export const MarketCard = styled.div`
    padding: 10px
    height: 20px;
    border-style: solid
    border-width: 1px
    border-radius: 35px
    border-color: ${props => props.itemMarket ? '#5e639f' : '#EC6565'}
    color: ${props => props.itemMarket ? '#5e639f' : '#EC6565'}
    background-color: transparent
    text-align: center
    display: flex
    justify-content: center
    align-items: center
    font-size: 10px
    font-weight: 600
`

export const MarketNameCard = styled.div`
    height: 20px;
    border-style: solid
    border-width: 1px
    border-radius: 35px
    border-color: #EC6565
    color: #EC6565
    background-color: transparent
    text-align: center
    font-size: 10px
    font-weight: 600;
    padding-left: 16px;
    padding-right: 16px;
`

export const OptionalNameBadge = styled.div`
    height: 20px;
    border-style: solid
    border-width: 1px
    border-radius: 35px
    border-color: gray
    color: gray
    background-color: transparent
    text-align: center
    font-size: 10px
    font-weight: 600;
    padding-left: 16px;
    padding-right: 16px;
`

export const NameCard = styled.div`
    height: 20px;
    border-style: solid
    border-width: 1px
    border-radius: 35px
    border-color: #5e639f
    color: #5e639f
    background-color: transparent
    text-align: center
    font-size: 10px
    font-weight: 600;
    padding-left: 16px;
    padding-right: 16px;
`
//Card option
export const CardOptionBody = styled.div`
    text-align: center
    padding-top: 5px
    padding-bottom: 5px
    height: 65%
    color: #5e639f
    font-size: 13px
    font-weight: 500
    border-style: solid
    border-top-left-radius: 20px
    border-top-right-radius: 20px
    border-color: #5e639f
    border-width: 1px
`

export const CardOptionBodySelected = styled.div`
    background-color: #5e639f
    text-align: center
    padding-top: 5px
    padding-bottom: 5px
    height: 65%
    color: white
    font-size: 13px
    font-weight: 500
    border-style: solid
    border-top-left-radius: 20px
    border-top-right-radius: 20px
    border-color: #5e639f
    border-width: 1px
`

export const CardOptionFooter = styled.div`
    text-align: center
    justify-content: center
    height: 35%
    background-color: #5e639f
    color: white
    font-weight: 500
    border-style: solid
    border-bottom-left-radius: 20px
    border-bottom-right-radius: 20px
    border-color: #545cd8
    border-width: 1px
`

export const CardOptionContainer = styled.div`
    border-radius: 20px
    width: 240px
    height: 80px
    margin-top: 20px
    margin-left: 5px
    display: inline-block
    box-shadow: 3px 5px 10px #babaca
    :hover {
        transition: 0.3s
        background-color: #dfe0ec
        cursor: pointer
    }
    :active {
        background-color: #bfc1d9
        cursor: pointer
    }
`
