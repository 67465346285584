export const SET_SELECTED_ID_BOM_ID = 'SET_SELECTED_BOM_ID'
export const SET_VIEW_MODE = 'SET_VIEW_MODE'
export const SET_SELECTED_APPLICATIONS = 'SET_SELECTED_APPLICATIONS'
export const SET_SELECTED_APPLICATIONS_EMPTY = 'SET_SELECTED_APPLICATIONS_EMPTY'
export const SELECTED_ALL_SCOPES_IN_STANDARDS = 'SELECTED_ALL_SCOPES_IN_STANDARDS'
export const SELECTED_TO_APPLY_WITH_REASONS = 'SELECTED_TO_APPLY_WITH_REASONS'
export const RESET_STATE = 'RESET_STATE'
export const SELECTED_TO_APPLY_FORCED = 'SELECTED_TO_APPLY_FORCED'
export const SELECTED_TO_APPLY_FORCED_EMPTY = 'SELECTED_TO_APPLY_FORCED_EMPTY'
export const RESET_SELECTEDS = 'RESET_SELECTEDS'


export const resetState = () => ({
	type: RESET_STATE
})

export const setSelectedIdBomToApproval = selectedBomBatchId => ({
	type: SET_SELECTED_ID_BOM_ID,
	selectedBomBatchId,
})

export const setViewMode = mode => ({
	type: SET_VIEW_MODE,
	mode,
})

export const setSelectedApplications = selectedApplication => ({
	type: SET_SELECTED_APPLICATIONS,
	selectedApplication,
})

export const setSelectedApplicationsEmpty = () => ({
	type: SET_SELECTED_APPLICATIONS_EMPTY,
})

export const setSelectedAllScopesInStandards = value => ({
	type: SELECTED_ALL_SCOPES_IN_STANDARDS,
	value,
})

export const setSelectedToApplyWithReasons = value => ({
	type: SELECTED_TO_APPLY_WITH_REASONS,
	value,
})

export const setSelectedToApplyForced = selectedApplication => ({
	type: SELECTED_TO_APPLY_FORCED,
	selectedApplication
})

export const setSelectedToApplyForcedEmpty = () => ({
	type: SELECTED_TO_APPLY_FORCED_EMPTY
})

export const resetSelecteds = () => ({
	type: RESET_SELECTEDS
})