import { gql } from '@apollo/client';

export const GET_USER_BY_REFRESH_TOKEN = gql`
query getUserByRefreshToken($refresh_token: String) {
    refresh_token(where: {refresh_token: {_eq: $refresh_token}}) {
      id
      user {
        id
        provider_azure {
          id
          name
          user_name
        }
      }
    }
  }
`

export const GET_USER = gql`
query getUsers {
  auth_users {
    id
    provider_azure {
      id
      name
      user_name
    }
  }
}
`
  
export const GET_USER_WITH_PERMISSIONS = gql`
subscription getUserWithPermissions($userId: uuid!) {
  auth_users(where: {id: {_eq: $userId}}) {
    id
    name
    email
    user_groups(where: {}) {
      id
      group {
        id
        group_permissions {
          id
          action {
            id
            name
            description
          }
        }
      }
    }
  }
}
`;


