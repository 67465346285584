import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface FilterValues {
	[key: string]: string[]
}

interface initialStateProps {
	showFilterMenu: Boolean
	filterValues: FilterValues
}

const initialState: initialStateProps = {
	showFilterMenu: false,
	filterValues: {},
}

const poManagement = createSlice({
	name: 'poManagement',
	initialState,
	reducers: {
		setShowFilterMenu(state, action: PayloadAction<Boolean>) {
			state.showFilterMenu = action.payload
		},

		setFilterValues(state, action: PayloadAction<FilterValues>) {
			state.filterValues = action.payload
		},
	},
})

export const { setShowFilterMenu, setFilterValues } = poManagement.actions
export default poManagement.reducer
